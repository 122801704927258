//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BackButton from '@/components/BackButton'
import PrivacyPolicyText from '@/components/Legal/PrivacyPolicy'
export default {
    name: 'PrivacyPolicy',
    components: {
      BackButton,
      PrivacyPolicyText
    },
    metaInfo(){
        return {
          title:'Privacy Policy'
        }
    },
}
